export const claimKeys = {
  firstName: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname',
  lastName: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname',
  email: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress',
  role: 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role',
  idp: 'http://schemas.microsoft.com/identity/claims/identityprovider',
  publisherId: 'publisher_id',
  sub: 'sid'
};

export const role = {
  admin: 'admin',
  publisherAdmin: 'publisherAdmin',
  publisher: 'publisher'
};
