import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, catchError, filter, map, of, shareReplay } from 'rxjs';
import { claimKeys } from './auth.const';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private session$: Observable<Session> | null = null;
  private user$: BehaviorSubject<UserModel> = new BehaviorSubject<UserModel>({});
  public get User() {
    return this.user$.getValue();
  }

  constructor(private httpClient: HttpClient, private router: Router) { }

  public getUser(): Observable<UserModel> {
    return this.user$;
  }

  public getSession(): Observable<Session> {
    if (!this.session$) {
      this.session$ = this.httpClient.get<Session>('bff/user').pipe(
        catchError(() => {
          return of(null)
        }),
        shareReplay(1)
      );

      this.session$.pipe(filter(s => !!s)).subscribe(session => {
        this.user$.next({
          firstName: session?.find(x => x.type == claimKeys.firstName)?.value,
          lastName: session?.find(x => x.type == claimKeys.lastName)?.value,
          email: session?.find(x => x.type == claimKeys.email)?.value,
          sub: session?.find(x => x.type == claimKeys.sub)?.value,
          roles: session?.filter(x => x.type == claimKeys.role)?.map(x => x.value),
          publisherIds: session?.filter(x => x.type == claimKeys.publisherId)?.map(x => x.value),
        });
      })
    }
    return this.session$;
  }

  public getIsAuthenticated(): Observable<boolean> {
    return this.getSession().pipe(
      map(session => session !== null)
    );
  }

  public login() {
    const absoluteUri = window.location.href;
    window.location.href = `./bff/login?returnUrl=${encodeURIComponent(absoluteUri)}`;
  }

  public logout() {
    window.location.href = `./bff/logout`;
  }

  public isInRole(role: string | null): Observable<boolean> {
    if (!role) {
      return of(true)
    }
    return this.getSession().pipe(
      map((session: Session) => !!session && session.some(x => x.type === 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role' && x.value === role))
    );
  }

  public isInAtLeastOneRole(roles: string[] | null): Observable<boolean> {
    if (!roles || roles.length < 1) {
      return of(true);
    }

    return this.getSession().pipe(
      map((claims) => {
        //If claims are empty, user isn't in any of the roles
        if (!claims) {
          return false;
        }

        //Fetch the values of the 'role' claims from the user's session
        const tokenRoles = claims.filter(claim => claim.type == claimKeys.role)
          .map(claim => claim.value);

        return roles.some(role => tokenRoles.includes(role));
      })
    );
  }


}

export type Claim = {
  type: string,
  value: string
}
export type Session = Claim[] | null;

export interface UserModel {
  sub?: string,
  firstName?: string;
  lastName?: string;
  email?: string;
  publisherIds?: string[];
  roles?: string[];
}