export const environment = {
    production: false,
    featureFlagRefreshTimeMs: 60000,
    applicationInsights: {
        connectionString: 'InstrumentationKey=4ce8c731-b481-4706-bc02-4dec6c1976d1;IngestionEndpoint=https://southafricanorth-1.in.applicationinsights.azure.com/;LiveEndpoint=https://southafricanorth.livediagnostics.monitor.azure.com/;ApplicationId=88834de1-e33a-413e-ab4d-02bf0f10ff7e'
    },
    support: {
        email: 'support@1Nebula.com'
    },
    fullstory: {
        isEnabled: false,
        orgId: 'FXWTB'
    }
};